.navigation {
  height: 60px;
  width: 100%;
  margin: 0;
  padding: 0;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: rgb(24, 24, 24);
  color: white;

  ul {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5em;
    margin: inherit;
    margin-right: rem;
    padding: inherit;

    li {
      letter-spacing: .25ch;
      text-transform: uppercase;
      list-style: none;
      padding: 0;
      font-weight: 100;
    }

    a {
      position: relative;
      font-size: 1.2rem;
      border: none;
      padding: 10px;
      cursor: crosshair;
    }
  }
}

nav ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2.55px;
  background-color: rgb(231, 195, 34);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 260ms ease-in;
}

nav ul li a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

nav ul li a:focus {
  outline: 0;
  position: relative;
}

nav ul li button:focus::after {
  outline: 0;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #e31c3d;
  transform: scaleX(1);
  transform-origin: right;
  transition: transform 260ms ease-in;
}
