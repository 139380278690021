@media only screen and (max-width: 813px) {
  html {
    width: 100vw;
  }
  nav {
    border-top: 1.25px solid black;
    background-color: rgb(255, 255, 255);
    /* text-align: center; */
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
  }
  .active {
    /* display: none; */
  }
  nav ul {
    text-align: center;
    /* display: flex; */
    /* overflow-x: hidden; */
  }
  nav ul li {
    display: flex;
  }
  nav ul li a {
    font-size: 2.5vw;
  }
  .work {
    width: 100%;
  }
  .card {
    margin: 10px;
  }
  .resume {
    width: 100%;
  }
  .resume .cards .card {
    width: auto;
  }
  .resume .resume_link {
    min-width: 50vw;
  }
  .projectContainer {
    /* min-width: 375px; */
    margin: 0;
    padding: 0;
  }
  .education>.cards>.card {
    min-width: fit-content;
  }
  .stack div {
    color: #FFF;
    background-color: black;
    border-radius: 0px;
    padding: 1px 10px 1px 10px;
    margin: 1vw;
  }
  .device {
    display: none;
  }
  fieldset {
    margin: 0;
    min-width: 90%;
  }
  .form input {
    width: fit-content;
    margin: 10px;
  }
  textarea {
    width: fit-content;
    margin: 0;
  }
  .form {
    min-width: fit-content;
  }
  .form .buttons {
    flex-wrap: wrap;
  }
  .contact .contact_container {
    flex-wrap: wrap;
  }
  footer {
    height: 11vh;
  }
}