.root {
  --corner-margin: 10%;
}

html {
  scroll-behavior: smooth;
}


@import "./icons";
.container {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section {
  min-height: 99vh;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-around;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

section h1 {
  text-align: center;
  font-size: max(45px, 4vw)
}

li {
  list-style-type: ">>";
  padding-inline-start: 1ch;
}


.home {
  height: 90vh;
  padding: 0;
}


.education {
  min-height: 90vh;
  padding-bottom: 100px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(0,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%230FF'/%3E%3Cstop offset='1' stop-color='%23CF6'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23F00'/%3E%3Cstop offset='1' stop-color='%23FC0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='9.899999999999999'%3E%3Cpath transform='translate(-24.5 0) rotate(-1.5 1409 581) scale(0.9797)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='3.3000000000000003' transform='translate(-35 20) rotate(1 800 450) scale(0.9999749999999998)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(2 -15) rotate(5 401 736) scale(0.9999749999999998)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='3'%3E%3Cpath transform='translate(120 2) rotate(-0.5 150 345) scale(0.9999)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='6.6000000000000005' transform='translate(-25 -55)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-64 16) scale(0.95)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.education>.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.education>.cards>.card {
  min-width: 400px;
  max-width: 25%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  min-height: 250px;
}

.card h2, p, i {
  padding-left: 10px;
  padding-right: 10px;
}

.card p, i {
  margin: 5px;
}

.card i {
  font-size: small;
  padding-left: 10px;
  padding-bottom: 10px;
}

.skills {
  /* background-color: #F0F0F0E1; */
  min-height: 90vh;
  background-color: #f4f4f4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='21' height='21' fill-opacity='0.41' fill='%23b8e7ff'/%3E%3C/svg%3E");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skills h1 {
  /* margin-bottom: 100px; */
}

.skill svg {
  font-size: 2vw;
}

.skill.cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-self: center;
  min-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

legend {
  font-size: max(14px, calc(1.5vw - 3px));
}

fieldset {
  margin: 10px;
  min-width: 350px;
}

fieldset ul {
  height: 100%;
  list-style: none;
  margin: 0px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

fieldset ul li {
  display: flex;
  align-items: center;
  margin: 10px;
}

fieldset ul li svg {
  margin-right: 1vw;
}

fieldset ul li p {
  margin: 0;
  padding: 0;
}


.work {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2000 1500'%3E%3Cdefs%3E%3Cpath fill='none' stroke-width='3' id='a' d='M0.74-509.63l485.39 352.65l-185.4 570.61h-599.97l-185.4-570.61L0.74-509.63 M0.74-510.87l-486.56 353.51l185.85 571.99h601.42L487.3-157.36L0.74-510.87L0.74-510.87z'/%3E%3C/defs%3E%3Cg style='transform-origin:center'%3E%3Cg transform='' style='transform-origin:center'%3E%3Cg transform='rotate(-30.4 0 0)' style='transform-origin:center'%3E%3Cg transform='translate(1000 750)'%3E%3Cuse stroke='%23ffece1' href='%23a' transform='rotate(1.9 0 0) scale(1.019)'/%3E%3Cuse stroke='%23ffebe1' href='%23a' transform='rotate(3.8 0 0) scale(1.038)'/%3E%3Cuse stroke='%23ffebe0' href='%23a' transform='rotate(5.7 0 0) scale(1.057)'/%3E%3Cuse stroke='%23ffeae0' href='%23a' transform='rotate(7.6 0 0) scale(1.076)'/%3E%3Cuse stroke='%23ffeae0' href='%23a' transform='rotate(9.5 0 0) scale(1.095)'/%3E%3Cuse stroke='%23ffe9e0' href='%23a' transform='rotate(11.4 0 0) scale(1.114)'/%3E%3Cuse stroke='%23ffe8e0' href='%23a' transform='rotate(13.3 0 0) scale(1.133)'/%3E%3Cuse stroke='%23ffe8df' href='%23a' transform='rotate(15.2 0 0) scale(1.152)'/%3E%3Cuse stroke='%23ffe7df' href='%23a' transform='rotate(17.1 0 0) scale(1.171)'/%3E%3Cuse stroke='%23ffe7df' href='%23a' transform='rotate(19 0 0) scale(1.19)'/%3E%3Cuse stroke='%23ffe6df' href='%23a' transform='rotate(20.9 0 0) scale(1.209)'/%3E%3Cuse stroke='%23ffe5df' href='%23a' transform='rotate(22.8 0 0) scale(1.228)'/%3E%3Cuse stroke='%23ffe5df' href='%23a' transform='rotate(24.7 0 0) scale(1.247)'/%3E%3Cuse stroke='%23ffe4df' href='%23a' transform='rotate(26.6 0 0) scale(1.266)'/%3E%3Cuse stroke='%23ffe4df' href='%23a' transform='rotate(28.5 0 0) scale(1.285)'/%3E%3Cuse stroke='%23ffe3df' href='%23a' transform='rotate(30.4 0 0) scale(1.304)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  background-position: left;
}

.resume .cards {
  letter-spacing: 1.15px;
  display: flex;
  gap: 5vh;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.resume .cards .card {
  background-color: #ffffff;
  text-align: center;
  width: 50ch;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.resume .resume_link {
  min-width: 50ch;
  text-align: center;
  margin: 5vh;
  padding: 2.5vh 0 2.5vh 0;
  background-color: #046B99;
  position: relative;
  z-index: 1;
}

.resume a {
  text-align: center;
  text-decoration: none;
  font-size: 1.25em;
  color: #FFF;
  width: 100%;
}

.contact {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2000 1500'%3E%3Cdefs%3E%3Crect fill='none' stroke-width='1' stroke-opacity='0.17' id='a' x='-400' y='-300' width='800' height='600'/%3E%3C/defs%3E%3Cg style='transform-origin:center'%3E%3Cg transform='rotate(180 0 0)' style='transform-origin:center'%3E%3Cg transform='rotate(-160 0 0)' style='transform-origin:center'%3E%3Cg transform='translate(1000 750)'%3E%3Cuse stroke='%23eeffd7' href='%23a' transform='rotate(10 0 0) scale(1.1)'/%3E%3Cuse stroke='%23deeecc' href='%23a' transform='rotate(20 0 0) scale(1.2)'/%3E%3Cuse stroke='%23ceddc2' href='%23a' transform='rotate(30 0 0) scale(1.3)'/%3E%3Cuse stroke='%23beccb7' href='%23a' transform='rotate(40 0 0) scale(1.4)'/%3E%3Cuse stroke='%23afbbac' href='%23a' transform='rotate(50 0 0) scale(1.5)'/%3E%3Cuse stroke='%239faaa2' href='%23a' transform='rotate(60 0 0) scale(1.6)'/%3E%3Cuse stroke='%238f9997' href='%23a' transform='rotate(70 0 0) scale(1.7)'/%3E%3Cuse stroke='%237f888c' href='%23a' transform='rotate(80 0 0) scale(1.8)'/%3E%3Cuse stroke='%236f7782' href='%23a' transform='rotate(90 0 0) scale(1.9)'/%3E%3Cuse stroke='%235f6677' href='%23a' transform='rotate(100 0 0) scale(2)'/%3E%3Cuse stroke='%234f556c' href='%23a' transform='rotate(110 0 0) scale(2.1)'/%3E%3Cuse stroke='%233f4462' href='%23a' transform='rotate(120 0 0) scale(2.2)'/%3E%3Cuse stroke='%23303357' href='%23a' transform='rotate(130 0 0) scale(2.3)'/%3E%3Cuse stroke='%2320224c' href='%23a' transform='rotate(140 0 0) scale(2.4)'/%3E%3Cuse stroke='%23101142' href='%23a' transform='rotate(150 0 0) scale(2.5)'/%3E%3Cuse stroke='%23000037' href='%23a' transform='rotate(160 0 0) scale(2.6)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.contact .contact_container {
  width: 50%;
  margin: auto;
  padding: 0;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-around;
}

.contact .contact_container svg {
  align-self: center;
  margin-right: 10px;
}

.contact .contact_container a {
  color: #fff;
  text-decoration: none;
  display: flex;
}

.contact .contact_container li {
  margin: 10px;
  padding: 8px 10px 8px 10px;
  border-radius: 2.5px;
  list-style: none;
}

.resume_link::after, .github::after, .linkedin::after, .email::after {
  content: " ";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e31c3d;
  transform-origin: right;
  transition: transform 230ms ease-in;
  transform: scaleX(0);
}

.resume_link:hover:after, .github:hover::after, .linkedin:hover::after, .email:hover::after {
  z-index: -1;
  transform: scaleX(1);
  transform-origin: left;
  /* filter: brightness(1.25); */
}

.contact .contact_container .email {
  background-color: #6a1dc2;
  /* transition: filter .35s ease-in-out; */
  position: relative;
  z-index: 0;
}

.contact .contact_container .github {
  /* transition: filter .35s linear; */
  background-color: #2eac69;
  position: relative;
  z-index: 0;
}

.contact .contact_container .linkedin {
  background-color: #2e94e7;
  /* transition: filter .35s ease-in-out; */
  position: relative;
  z-index: 0;
}

.form {
  margin-top: 5vh;
  font-size: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 400px;
  margin-bottom: 10vh;
}

.form input {
  padding: 10px;
  font-size: inherit;
  height: 5vh;
}

.form label {
  font-size: larger;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form .textarea {
  display: flex;
  flex-direction: column;
}

.form textarea {
  font-size: 1.5rem;
  font-family: Roboto, sans-serif;
  padding: 10px;
  resize: none;
  min-width: 50vw;
  height: 100px;
  margin-bottom: 10px;
}

.form .buttons {
  display: flex;
  justify-content: space-between;
}

.form .buttons button {
  align-items: center;
  min-width: max-content;
  font-size: 1.5rem;
  padding: 5px;
}

.footer {
  font-size: smaller;
  font-weight: 100;
  padding: 10px;
  color: rgb(255, 255, 255);
  background-color: rgb(89, 90, 92);
  text-align: center;
}