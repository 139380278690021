.hero-bg {
    position: relative;
    height: 100%;
    width: 100%;
    background-attachment: fixed;
    background-color: rgb(255, 196, 0);
    background-image: url('../assets/hero-bg3.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .hero-bg-filter {
    /* position: absolute;
    padding: 0;
    margin: 0;
    background: linear-gradient(0deg, #00000088 30%, #ffffff44 100%);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    height: 100%;
    width: 100%; */
  }
  
  .hero-bg h1 {
    position: relative;
    font-size: max(10vw, 75px);
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    /* background-clip: text;
    color: rgba(0, 0, 0, 0); */
    /* color: rgb(255, 255, 255); */
    /* -webkit-background-clip: text; */
    /* mix-blend-mode: multiply; */
    
  }
  
  .hero-bg p {
    font-size: max(14px, calc(1.5vw - 3px));
    font-weight: bold;
    letter-spacing: 1vw;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
  

  }