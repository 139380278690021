.HTML svg {
  color: #FF6600;
}

.CSS svg {
  color: #3d40ff;
}

.JS svg {
  color: #FFCC00
}

.PSQL svg {
  color: #330066
}

.React svg {
  color: #0055ff;
}

.Node svg {
  color: #006600;
}

.Express svg {
  color: #CC33CC;
}

.REST svg {
  color: #FC3A52;
}

.WordPress svg {
  color: #2a126d;
}

.PS svg {
  color: #18206F;
}

.MS svg {
  color: #3EA060;
}

.GitHub svg {
  color: rgb(66, 6, 90);
}

.Git svg {
  color: rgb(192, 117, 47);
}

.SQL svg {
  color: rgb(8, 0, 114);
}